(function () {
  'use strict';

  angular
    .module('modal')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('modal', {
        url: '/modal',
        templateUrl: 'modal/modal.tpl.html',
        controller: 'ModalCtrl',
        controllerAs: 'modal'
      });
  }
}());
